import Accordion from "react-bootstrap/Accordion";
import video from "../../assets/images/img_video.jpg";
import admin from "../../assets/images/img_admin.png";

function PractitionerSignUpBody() {
  return (
    <div className="practitioner-signup-body">
      <h1>Perks of being a LumiLife Practitioner</h1>
      <div className="circles-container row m-0">
        <div className="d-flex flex-column justify-content-top align-items-center text-center col-4">
          <div className="circles circle1">
            <div>1</div>
          </div>
          <h2 className="yellow">We champion you
</h2>
          <p>
          We'll lift you up and help you to stand out by marketing your unique expertise and match you with women who are looking for your skills.

          </p>
        </div>
        <div className="d-flex flex-column justify-content-top align-items-center text-center col-4">
          <div className="circles circle2">
            <div>2</div>
          </div>
          <h2 className="pink">Forge your own path</h2>
          <p>
          Set your own schedule and fees and feel the freedom of working when and where you want -from home or on the road.

          </p>
        </div>
        <div className="d-flex  flex-column justify-content-top align-items-center text-center col-4">
          <div className="circles circle3">
            <div>3</div>
          </div>
          <h2 className="rose">Simplified admin
</h2>
          <p>We take care of scheduling, telehealth access, payments and invoicing so you can focus on what you do best.
</p>
        </div>
      </div>

      <div className="video-service row m-0">
        <div className="col-6">
          <div className="left">
            <h3>Secure Video Service</h3>
            <p>
              Instant suggestions for the best practitioner just for you. The
              more you answer, the more refined the results are displayed.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div className="right d-flex justify-content-center">
            <img src={video}></img>
          </div>
        </div>
      </div>

      <div className="simplified-admin row m-0">
        <div className="col-6">
          <div className="right d-flex justify-content-center">
            <img src={admin}></img>
          </div>
        </div>
        <div className="col-6">
          <div className="left">
          <h3>Simplified Admin</h3>
            <p>We take care of scheduling, telehealth access, payments and invoicing so you can focus on what you do best.
</p>
<h3>Support women to shine</h3>
            <p>Use your skills to empower women and make meaningful change.

</p>
<h3>It’s free to join
</h3>
            <p>Access to our platform is free and you can cancel anytime.*
*It is completely free to join and be seen on LumiLife. A 15% service fee, calculated based on the provider's rates, is applicable to all sessions conducted via the LumiLife platform (commencing after the initial two months free period*).

</p>
          </div>
        </div>
      </div>

      <div className="faqs">
        <h2>FAQs</h2>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Am I the right one for you?</Accordion.Header>
            <Accordion.Body>
              We charge no monthly fee or joining fees and you can cancel or
              pause your account at anytime. We chage a flat 20% service fee for
              each session booked. This helps keep the platform running and
              enables us to provide support.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Can I pause my account when I have enough work?
            </Accordion.Header>
            <Accordion.Body>
              We charge no monthly fee or joining fees and you can cancel or
              pause your account at anytime. We chage a flat 20% service fee for
              each session booked. This helps keep the platform running and
              enables us to provide support.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>How will I get paid?</Accordion.Header>
            <Accordion.Body>
              We charge no monthly fee or joining fees and you can cancel or
              pause your account at anytime. We chage a flat 20% service fee for
              each session booked. This helps keep the platform running and
              enables us to provide support.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>How much does it cost?</Accordion.Header>
            <Accordion.Body>
              We charge no monthly fee or joining fees and you can cancel or
              pause your account at anytime. We chage a flat 20% service fee for
              each session booked. This helps keep the platform running and
              enables us to provide support.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default PractitionerSignUpBody;
