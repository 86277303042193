import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import useProfile from "../../hooks/useProfile";
import useUser from "../../hooks/useUser";
import UserCard from "./components/UserCard";
import { useNavigate } from "react-router-dom";

function AdminUserList({ Component: component }) {
  const { role } = useProfile();
  const { nameOrder, users } = useUser();

  const navigate = useNavigate();

  console.log('users::',users);

  return (
    <>
      <DashboardLayout>
        <div className="admin-practitioner-dashboard-home">
          <div className="title-bar">
            {/* <h1><span>List of Users <span className="pending-approval">({nameOrder ? nameOrder.length : 0})</span></span></h1> */}
            <h1><span>List of Users <span className="pending-approval"></span></span></h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>
          <div className="yellow-box">
            {users && users.length > 0 ? (
              users.map((item) => {
                return <UserCard 
                  id={item._id}
                  name={item.name}
                  email={item.email}
                  description={item.description}
                  date={new Date()}
                  role={item.role}
                />
              })
            ) : (
              <div align="center"> No matches.</div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default AdminUserList;
