import React, { useState } from "react";
import usePasswordEye from "../../hooks/usePasswordEye";
import Header from "../../components/Header/index.js";
import Footer from "../../components/Footer/index.js";
import { Link } from "react-router-dom";
import asterisk from "../../assets/images/asterisk.png";
import PractitionerSignUpBody from "../../components/PractitionerSignUpBody";
import { useUserStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import { Email, Password, Actions, ErrorMessage } from "./forms/form";
import "react-toastify/dist/ReactToastify.css";
import useLoader from "../../hooks/useLoader";
import { validateEmail } from "../../utils/utilityFunctions";
import useEnterKey from "../../hooks/useEnterKey";

function PractitionerLogin() {
  const { icon, clickHandler, isReadable } = usePasswordEye();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const doLogin = useUserStore((state) => state.doLogin);
  const loginCallback = async () => {
    try {
      setError("");

      if (!email || !validateEmail(email)) {
        setError("Please provide a valid email.");
        return;
      }

      if (!password) {
        setError("Please provide a password.");
        return;
      }

      showLoader("Logging in. Please wait..");
      const res = await doLogin(email, password, "practitioner");
      closeMessages();
      if (res.error) {
        showError(res.message);
        return;
      }

      navigate(`/${res.result.profile.role}/dashboard`);
    } catch (error) {
      closeMessages();
      showError("Something went wrong.");
    }
  };
  useEnterKey(loginCallback);

  return (
    <>
      <Header></Header>
      <div className="practitioner-login">
        <div className="banner">
          <div className="row">
            <div className="left col-6">
              <div className="signup-form">
                <h3>Practitioner Login </h3>
                <Email email={email} setEmail={setEmail} />
                <Password
                  password={password}
                  setPassword={setPassword}
                  icon={icon}
                  clickHandler={clickHandler}
                  isReadable={isReadable}
                />
                <ErrorMessage error={error} />
                <Actions onAdd={loginCallback} />
                <h5>
                  <Link to="/practitioner-signup">Sign Up</Link> if you don't
                  have an account yet
                </h5>
              </div>
            </div>
            <div className="bullets col-6">
              <h3>Are you a wellbeing professional who is passionate about supporting women's growth?</h3>
              <br />
              <h5>JOIN LUMILIFE FOR FREE AND PAY ZERO COMMISSION ON ANY OF YOUR APPOINTMENTS IN THE FIRST TWO MONTHS (cancel anytime)
</h5>
<br />
<h5>We are looking for a range of health and well-being practitioners to be a part of the LumiLife journey. If you would like to join a community that offers women the space to find whatever support they are needing to empower their personal growth</h5>
              <ul>
                <li>
                  <img src={asterisk}></img>Get instant access to a large pool
                  of clients
                </li>
                <li>
                  <img src={asterisk}></img>Accept online bookings and payments
                </li>
                <li>
                  <img src={asterisk}></img>Medicare Rebates & Referrals
                </li>
                <li>
                  <img src={asterisk}></img>Connect using our secure video and
                  chat services
                </li>
                <li>
                  <img src={asterisk}></img>Work on your schedule
                </li>
                <li>
                  <img src={asterisk}></img>End-to-end support
                </li>
                <li>
                  <img src={asterisk}></img>Accept in-person & telehealth
                  appointments
                </li>
                <li>
                  <img src={asterisk}></img>Be seen by our supportive community of women

                </li>
                <li>
                  <img src={asterisk}></img>Accept online or in person appointments and payments

                </li>
              </ul>
              <h5>Start reaching out today.</h5>
            </div>
          </div>
        </div>
      </div>
      <PractitionerSignUpBody></PractitionerSignUpBody>
      <Footer></Footer>
    </>
  );
}

export default PractitionerLogin;
