function Banner() {
  return (
    <div id="banner">
      <div id="content">
        <h1>Feeling stuck? Find the support you need to shine</h1>
        <h3>
          Connect with a practitioner that understands and supports you, and get
          your personal and professional growth back on track.
        </h3>
        <a className="lumi-btn">I'm ready</a>
      </div>
    </div>
  );
}

export default Banner;
