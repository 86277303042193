import lumilifeQuickAccessLogo from "../../assets/images/lumi-life-quick-access-logo.png";
import facebook from "../../assets/images/icon_facebook.png";
import instagram from "../../assets/images/icon_instagram.png";
import { useState } from "react";
import { useUserStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../ConfirmModal";
import { performLogout } from "../../utils/utilityFunctions";

function Footer() {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const jwtData = useUserStore((state) => state.jwtData);
  const loginLogoutAction = (e) => {
    e.preventDefault();
    console.log(jwtData);
    if (jwtData) setShowLogoutConfirm(true);
    else navigate("/practitioner-login");
  };

  return (
    <div id="quick-access-wrap">
      <div id="quick-access">
        <div className="row m-0">
          <div className="col-3 lumilife-logo-box">
            <img height={120} src={lumilifeQuickAccessLogo}></img>
          </div>

          <div className="col-3 quick-access-links">
            <h1>Quick Access</h1>
            <ul className="list">
              {/* <li>
                <a href="#" className="text">
                  Browse Practitioners
                </a>
              </li> */}
              <li>
                <a href="#" className="text">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="text">
                  Medicate Rebates
                </a>
              </li>
              <li>
                <a href="/faq" className="text">
                  FAQS
                </a>
              </li>
            </ul>
          </div>

          <div className="col-3 for-practitioners-links ">
            <h1>For Practitioners</h1>
            <ul className="list">
              <li>
                <a href={""} className="text" onClick={loginLogoutAction}>
                  {jwtData ? "Logout" : "Login"}
                </a>
              </li>
              <li>
                <a href="#" className="text">
                  Forgot Password
                </a>
              </li>
              <li>
                <a href="/faq" className="text">
                  FAQS
                </a>
              </li>
            </ul>
          </div>

          <div className="col-3 lumilife-logo-box">
            <a href="#">
              <img src={facebook}></img>
            </a>
            <a href="#">
              <img src={instagram}></img>
            </a>
            <div>LumiLife Copyright 2023</div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={showLogoutConfirm}
        setShow={setShowLogoutConfirm}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          performLogout();
        }}
        onCancel={() => {}}
      ></ConfirmModal>
    </div>
  );
}

export default Footer;
