import React, { useState, useEffect, useMemo } from "react";
import Banner from "./components/Banner";
import Specialties from "./components/Specialties";
import HowItWorks from "./components/HowItWorks";
import GetMatched from "./components/GetMatched";
import Practitioners from "./components/Practitioners";
import Benefits from "./components/Benefits";
import GoodReads from "./components/GoodReads";
import EmailForm from "./components/EmailForm";
import InsuranceAndMedicare from "./components/InsuranceAndMedicare";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SpecializationStar from "./components/SpecializationStar";

function HomePage() {
  //const jwtData = useStore((state) => state.jwtData);
  //const setLogout = useStore((state) => state.setLogout);

  /*useEffect(() => {
    validateAccess(jwtData);
    }, [jwtData]);*/

  return (
    <>
      {/*<Header jwtData={jwtData} setLogout={setLogout}></Header>*/}
      <Header></Header>
      <div id="homepage">
        <Banner></Banner>
        <Specialties></Specialties>
        <HowItWorks></HowItWorks>
        <GetMatched></GetMatched>
        <Practitioners></Practitioners>
        <Benefits></Benefits>
        <SpecializationStar></SpecializationStar>
        <GoodReads></GoodReads>
        <EmailForm></EmailForm>
        <InsuranceAndMedicare></InsuranceAndMedicare>
      </div>
      <Footer></Footer>
    </>
  );
}

export default HomePage;
