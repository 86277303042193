import { professionOptions } from "../../utils/globals";
import { useState } from "react";

export const Profession = ({ value, setValue, professions, setValueText }) => {
  return (
    <>
      <div className="labels mt-4">Profession</div>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setValueText(e.target.options[e.target.selectedIndex].text);
        }}
      >
        {professions.map((item) => {
          return <option value={item.value}>{item.label}</option>;
        })}
      </select>
    </>
  );
};

export const Name = ({ firstName, lastName, setFirstname, setLastname }) => (
  <>
    <div className="fullname-container row">
      <div className="col-6 pl-0">
        <div className="labels mt-4">First Name</div>
        <input
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstname(e.target.value);
          }}
          placeholder="Your given name"
        ></input>
      </div>
      <div className="col-6 pr-0">
        <div className="labels mt-4">Last Name</div>
        <input
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastname(e.target.value);
          }}
          placeholder="Your surname"
        ></input>
      </div>
    </div>
  </>
);

export const Email = ({ email, setEmail }) => (
  <>
    <div className="labels mt-4">Email Address</div>
    <input
      type="text"
      value={email}
      onChange={(e) => {
        setEmail(e.target.value);
      }}
      placeholder="Your email address"
    ></input>
  </>
);

export const Password = ({
  password,
  setPassword,
  icon,
  clickHandler,
  isReadable,
}) => (
  <>
    <div className="labels mt-4">Password</div>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      ></input>
    </div>
  </>
);

export const Password2 = ({
  password2,
  setPassword2,
  icon,
  clickHandler,
  isReadable,
}) => (
  <>
    <div className="labels mt-4">Confirm Password</div>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
      ></input>
    </div>
  </>
);

export const Actions = ({ onAdd }) => (
  <>
    <button onClick={onAdd} className="lumi-btn">
      Create Practitioner Account
    </button>
  </>
);

export const ErrorMessage = ({ error }) => (
  <>
    <h5 className="error">{error}</h5>
  </>
);
