import React, { useState } from "react";
import usePasswordEye from "../../hooks/usePasswordEye";
import Header from "../../components/Header/index.js";
import Footer from "../../components/Footer/index.js";
import { useUserStore } from "../../store/userStore";
import { Link } from "react-router-dom";
import asterisk from "../../assets/images/asterisk.png";
import PractitionerSignUpBody from "../../components/PractitionerSignUpBody";
import {
  Profession,
  Name,
  Email,
  Password,
  Password2,
  Actions,
  ErrorMessage,
} from "./form";
import { validateEmail } from "../../utils/utilityFunctions";
import useLoader from "../../hooks/useLoader";
import usePractitioner from "../../hooks/usePractitioner";
import { useNavigate } from "react-router-dom";

function PractitionerSignUp() {
  const [profession, setProfession] = useState('Psychologist');
  const [professionText, setProfessionText] = useState('Psychologist');  
  const { icon, clickHandler, isReadable } = usePasswordEye();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [role, setRole] = useState("practitioner");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const { professions } = usePractitioner();
  const doRegistration = useUserStore((state) => state.doRegistration);

  return (
    <>
      <Header></Header>
      <div className="practitioner-signup">
        <div className="banner">
          <div className="row">
            <div className="left col-6">
              <div className="signup-form">
                <h3>Create a Practitioner Account</h3>

                <Profession value={profession} setValue={setProfession} professions={professions} setValueText={setProfessionText} />
                <Name
                  firstName={firstname}
                  lastName={lastname}
                  setFirstname={setFirstname}
                  setLastname={setLastname}
                />
                <Email email={email} setEmail={setEmail} />
                <Password
                  password={password}
                  setPassword={setPassword}
                  icon={icon}
                  clickHandler={clickHandler}
                  isReadable={isReadable}
                />
                <Password2
                  password2={password2}
                  setPassword2={setPassword2}
                  icon={icon}
                  clickHandler={clickHandler}
                  isReadable={isReadable}
                />

                <ErrorMessage error={error} />

                <div className="terms">
                  By creating an account, you agree to Lumilife's{" "}
                  <Link>Terms of Services</Link>
                </div>
                <Actions
                  onAdd={async () => {
                    try {
                      setError("");

                      if (!profession) {
                        setError("Please set your profession.");
                        return;
                      }

                      if (!firstname) {
                        setError("Please provide your first name.");
                        return;
                      }

                      if (!lastname) {
                        setError("Please provide your last name.");
                        return;
                      }

                      if (!email || !validateEmail(email)) {
                        setError("Please provide a valid email.");
                        return;
                      }

                      if (!password) {
                        setError("Please provide a password.");
                        return;
                      }

                      if (password != password2) {
                        setError("Passwords should match.");
                        return;
                      }

                      showLoader("Processing. Please wait..");
                      const res = await doRegistration(
                        professionText,
                        firstname,
                        lastname,
                        email,
                        password,
                        password2,
                        role
                      );
                      closeMessages();
                      if (res.error) {
                        showError(res.message);
                        return;
                      }

                      setTimeout(() => navigate("/practitioner-login"), 8000);
                      showMessage("Success. Redirecting you to sign in.");
                    } catch (error) {
                      console.log(error.message);
                    }
                  }}
                />

                <h5>
                  <Link to="/practitioner-login">Sign In</Link> if you have an
                  account.
                </h5>
              </div>
            </div>
            <div className="bullets col-6">
              <h3>Are you a wellbeing professional who is passionate about supporting women's growth?
</h3>
              <ul>
                <li>
                  <img src={asterisk}></img>Get instant access to a large pool
                  of clients
                </li>
                <li>
                  <img src={asterisk}></img>Accept online bookings and payments
                </li>
                <li>
                  <img src={asterisk}></img>Medicare Rebates & Referrals
                </li>
                <li>
                  <img src={asterisk}></img>Connect using our secure video and
                  chat services
                </li>
                <li>
                  <img src={asterisk}></img>Work on your schedule
                </li>
                <li>
                  <img src={asterisk}></img>End-to-end support
                </li>
                <li>
                  <img src={asterisk}></img>Accept in-person & telehealth
                  appointments
                </li>
              </ul>
              <h5>Start reaching out today.</h5>
            </div>
          </div>
        </div>
      </div>
      <PractitionerSignUpBody></PractitionerSignUpBody>
      <Footer></Footer>
    </>
  );
}

export default PractitionerSignUp;
