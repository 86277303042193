import MentalHealthBG from "../../../assets/images/bg_mental_therapists.jpg";
import NaturalTherapistBG from "../../../assets/images/bg_natural_therapists.jpg";
import ParentingBG from "../../../assets/images/bg_parenting.jpg";
import NutritionBG from "../../../assets/images/bg_nutrition.jpg";
import LifeBG from "../../../assets/images/bg_life_business.jpg";
import FinanceBG from "../../../assets/images/bg_finance.jpg";

import SpecialtyItem from "../components/SpecialtyItem";

function Specialties() {
  return (
    <div id="specialties">
      <ul>
        <SpecialtyItem
          src={MentalHealthBG}
          color={"blue"}
          name={"Mental Health Therapists"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={NaturalTherapistBG}
          color={"yellow"}
          name={"Natural Therapists"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={ParentingBG}
          color={"orange"}
          name={"Parenting & Relationships"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={NutritionBG}
          color={"rose"}
          name={"Nutritionists & Dietiticians"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={LifeBG}
          color={"pink"}
          name={"Life & Business Coaches"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={FinanceBG}
          color={"lightgreen"}
          name={"Financial Wellbeing"}
        ></SpecialtyItem>
        <div className="clear-both"></div>
      </ul>
    </div>
  );
}

export default Specialties;
