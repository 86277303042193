import Doctor from "../../../assets/images/dr_1.jpg";
import CalendarIcon from "../../../assets/images/icon_calendar.png";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import { Link } from "react-router-dom";

function SessionCard() {
  return (
    <>
      <div className="session-no-schedule">
        <span>You don't have current schedule at the moment.</span>
      </div>
    </>
  );
}

export default SessionCard;
