import GoodReadsBG from "../../../assets/images/goodreads.png";
import ArticlePhoto1 from "../../../assets/images/photo_article1.jpg";
import ArticlePhoto2 from "../../../assets/images/photo_article2.jpg";
import ArticlePhoto3 from "../../../assets/images/photo_article3.jpg";

function GoodReads() {
  return (
    <div id="goodreads">
      <div className="row m-0">
        <div className="good-reads-script-container col-3">
          <img className="good-reads-script" src={GoodReadsBG}></img>
        </div>
        <div className="col-9">
          <div className="strips"></div>
        </div>
      </div>
      <div id="articles">
        <div className="article">
          <img className="article-photo" src={ArticlePhoto1}></img>
          <div className="article-card">
            <h3>How to find real friends</h3>
            <div className="divider"></div>
            <span className="article-snippet">
              A guide to knowing how to find real people who can contribute
              significance into your life.
            </span>
          </div>
        </div>
        <div className="article">
          <img className="article-photo" src={ArticlePhoto2}></img>
          <div className="article-card">
            <h3>
              Synonymously overcoming inner fear with setting physical records
              for yourself
            </h3>
            <div className="divider"></div>
            <span className="article-snippet">
              A guide to knowing how to find real people who can contribute
              significance into your life.
            </span>
          </div>
        </div>
        <div className="article">
          <img className="article-photo" src={ArticlePhoto3}></img>
          <div className="article-card">
            <h3>How to find real friends</h3>
            <div className="divider"></div>
            <span className="article-snippet">
              A guide to knowing how to find real people who can contribute
              significance into your life.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodReads;
