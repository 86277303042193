import Doctor from "../../../assets/images/dr_1.jpg";
import CalendarIcon from "../../../assets/images/icon_calendar.png";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import { formatSpecialtiesCard, formatIntroductionMessage } from "../../../helpers/practitionerHelper";
import { Link } from "react-router-dom";

function PractitionerCard({
  firstname,
  lastname,
  role,
  status,
  message,
  specialties,
  id,
  redirectToAdmin,
  redirectToCalendar,
}) {
  return (
    <>
      <div className="session-card">
        <div className="row-container">
          <div
            className="half"
            onClick={() => {
              redirectToAdmin(id);
            }}
          >
            <img className="photo" src={Doctor}></img>
            <h3>
              Dr. {firstname} {lastname}
            </h3>
            <h4>
             <span>{formatIntroductionMessage(message)}</span>
            </h4>
          </div>
          <div className="half">
            <span className="date">
              <img className="calendar-icon" src={CalendarIcon}></img>Registration Date
            </span>
            <span className="day-time">Monday, 12.30 pm</span>
          </div>
        </div>
        <div className="row-container mt-4">
          <div className="half">
            <span className="specialties"> {formatSpecialtiesCard(specialties)}</span>
            <div className="mt-4">
              <button 
              className="lumi-btn join-call"
              onClick={() => redirectToCalendar(id)}
              >Book Call</button></div>
          </div>
          <div className="half text-right">
            <div className="circle-icon">
              <img src={ContactIcon}></img>
            </div>
            <div className="circle-icon">
              <img src={EmailIcon}></img>
            </div>
          </div>
        </div>
        <div className="text-right mt-4"></div>
      </div>
    </>
  );
}

export default PractitionerCard;
