import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/images/logo.png";
import logo_mobile from "../../assets/images/logo_mobile.png";
import { useUserStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../ConfirmModal";
import { performLogout } from "../../utils/utilityFunctions";

function Header() {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const jwtData = useUserStore((state) => state.jwtData);
  const loginLogoutAction = (e) => {
    e.preventDefault();
    if (jwtData) setShowLogoutConfirm(true);
    // else navigate("/signin"); -> staging
    else navigate("/practitioner-login");
  };

  const [hamburgerStatus, setHamburgerStatus] = useState(false);
  const hamburgerClickHandler = () => {
    setHamburgerStatus(!hamburgerStatus);
  };

  return (
    <>
      <div className="header">
        <div id="hamburger" onClick={hamburgerClickHandler}>
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <div></div>
          </div>
        </div>
        <div>
          <Link to="/">
            <img id="logo" src={logo}></img>
            <img id="logo_mobile" src={logo_mobile}></img>
          </Link>
        </div>
        <nav className={hamburgerStatus ? "nav-open" : ""}>
          <ul>
            <li>
              {/* <Link className="lumi-btn get-matched" to={"/get-matched"}> */}
              <Link className="lumi-btn get-matched" to={"#"}>
                Get Matched
              </Link>
            </li>
            {/* <li>
              <Link to={"/browse-practitioners"}>Browse Practitioners</Link>
            </li> */}
            <li>
              <Link to={"#"}>How It Works</Link>
            </li>
            <li>
              <a href={""} onClick={loginLogoutAction}>
                {jwtData ? "Logout" : "Login"}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <ConfirmModal
        show={showLogoutConfirm}
        setShow={setShowLogoutConfirm}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          performLogout();
        }}
        onCancel={() => {}}
      ></ConfirmModal>
    </>
  );
}

export default Header;
