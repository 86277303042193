import Phones from "../../../assets/images/phones.png";
import BGCircles from "../../../assets/images/bg_circles.png";

function Benefits() {
  return (
    <>
      <div id="benefits">
        <div className="row">
          <div id="phones" className="col-5">
            <img id="phones" src={Phones}></img>
          </div>
          <div id="content" className="col-7">
            <div className="content-box">
              <h1>Benefits from:</h1>

              <ul className="list">
                <li>
                  <div className="num">1</div>
                  <div className="text">
                    A dedicated platform to empower women to heal and grow
                  </div>
                </li>
                <li>
                  <div className="num">2</div>
                  <div className="text">
                    Fast, direct access to qualified practitioners
                  </div>
                </li>
                <li>
                  <div className="num">3</div>
                  <div className="text">
                    A matching tool to help you to find the right practitioner
                  </div>
                </li>
                <li>
                  <div className="num">4</div>
                  <div className="text">
                    Australia-wide access to telehealth and in-person
                    appointments
                  </div>
                </li>
                <li>
                  <div className="num">5</div>
                  <div className="text">
                    Medicare and insurance rebates where possible
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="benefits-mobile">
        <div id="phone-container">
          <img id="phones" src={Phones}></img>

          <div id="content">
            <div className="content-box">
              <h1>Benefits from:</h1>

              <ul className="list">
                <li>
                  <div className="num">1</div>
                  <div className="text">
                    A dedicated platform to empower women to heal and grow
                  </div>
                </li>
                <li>
                  <div className="num">2</div>
                  <div className="text">
                    Fast, direct access to qualified practitioners
                  </div>
                </li>
                <li>
                  <div className="num">3</div>
                  <div className="text">
                    A matching tool to help you to find the right practitioner
                  </div>
                </li>
                <li>
                  <div className="num">4</div>
                  <div className="text">
                    Australia-wide access to telehealth and in-person
                    appointments
                  </div>
                </li>
                <li>
                  <div className="num">5</div>
                  <div className="text">
                    Medicare and insurance rebates where possible
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefits;
