import Asterisk from "../../../assets/images/asterisk_colored.png";
function SpecializationStar() {
  return (
    <div className="specialization-star">
      <div className="content">
        <div className="star-row">
          <div className="star-item">
            <h1 className="blue">Mental Health Therapists</h1>
            <h2>
              Talk to an understanding professional who can equip you with tools
              to find inner happiness.
            </h2>
          </div>
          <div className="star-middle"></div>
          <div className="star-item">
            <h1 className="rose">Nutritionists & Dieticians</h1>
            <h2>
              Understand what your body needs to function at its best and feel
              better inside and out.
            </h2>
          </div>
        </div>

        <div className="star-row middle">
          <div className="star-item push-left">
            <h1 className="yellow">Natural Therapists</h1>
            <h2>
              Support your wellbeing with holistic therapies including
              kinesiology and naturopathy.
            </h2>
          </div>
          <div className="star-middle">
            <img src={Asterisk}></img>
          </div>
          <div className="star-item push-right">
            <h1 className="pink">Life & Business Coaches</h1>
            <h2>
              Work with a coach to define your goals and set strategies to
              realise your purpose and potential.
            </h2>
          </div>
        </div>

        <div className="star-row">
          <div className="star-item">
            <h1 className="orange">Family Counsellors</h1>
            <h2>
              Ease the challenges that you or your child face at home with
              guided counselling.
            </h2>
          </div>
          <div className="star-item">
            <h1 className="lightgreen">Financial Consultants</h1>
            <h2>
              Talk to a mentor, financial adviser or counsellor and take control
              of your financial future.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecializationStar;
