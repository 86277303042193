import { useAdminStore } from "../store/adminStore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function useAdminPendingApproval() {
  const [pending, setPending] = useState(null);
  const [pendingDocument, setPendingDocument] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const [approve, setApprove] = useState(false);
  let [redirection, setRedirection] = useState(null);
  const doFetchPendingApproval = useAdminStore(
    (state) => state.doFetchPendingApproval
  );
  const doFetchPendingDocumentApproval = useAdminStore(
    (state) => state.doFetchPendingDocumentApproval
  );
  const doAcceptPending = useAdminStore((state) => state.doAcceptPending);
  const navigate = useNavigate();

  useEffect(()=> {
    try {
      const fetch = async() => {
        const response = await doFetchPendingApproval({status: 'pending'});
        if(response) {
          const {result} = response;
          setPending(result);
        } else {
          setPending(null);
        }
      }
       fetch();
    } catch(e) {
      console.log("useAdminPendingApproval.useEffect", e.message);
    }
  },[accepted]);

  useEffect(()=> {
    try {
      const fetch = async() => {
        const response = await doFetchPendingDocumentApproval({document_status: 'pending'});
        if(response) {
          console.log('useEffect::doFetchPendingDocumentApprovalresponse', response);
          const {result} = response;
          setPendingDocument(result);
        } else {
          setPendingDocument(null);
        }
      }
       fetch();
    } catch(e) {
      console.log("useAdminPendingApproval.useEffect", e.message);
    }
  },[accepted]);

  useEffect(()=> {
      redirection != null && navigate(`/admin/practitioner/profilepage/${redirection}`)
  },[redirection]);

  useEffect(()=> {
    console.log('approve-changed::',approve);
  },[approve]);

  return { 
    pending, 
    redirection,
    pendingDocument,

    handler: (id) => {
      setRedirection(id)
    },
    accepted,
    hasAccepted: () => {
      setAccepted(!accepted);
    },
    acceptHandler: (id) => {
      try {
        const accept = async() => {
          const response = await doAcceptPending({_id: id, status: 'active'});
          if(response) {
            setApprove('active');
          }
        }
        accept();
      } catch(e) {
        console.log("useAdminPendingApproval.useEffect", e.message);
      }
    },
    approve,
  };
}

export default useAdminPendingApproval;
