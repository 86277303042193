import Doctor from "../../../assets/images/dr_1.jpg";
import CalendarIcon from "../../../assets/images/icon_calendar.png";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import { formatUserListDate } from "../../../helpers/practitionerHelper";
import { Link } from "react-router-dom";

function UserCard({
  id,
  name,
  email,
  description,
  date,
  role
}) {
  return (
    <>
      <div className="session-card">
        <div className="row-container">
          <div
            className="half"
            onClick={() => 'clicked'}
          >
            <img className="photo" src={Doctor}></img>
            <h3>
              {email}
            </h3>
            <h4>
             <span>{name}</span>
            </h4>
          </div>
          <div className="half">
            <span className="date">
              <img className="calendar-icon" src={CalendarIcon}></img>Registration Date
            </span>
            <span className="day-time">{formatUserListDate(date)}</span>
          </div>
        </div>
        <div className="row-container mt-4">
          <div className="half">
            <span className="specialties"> {role}</span>
          </div>
          <div className="half text-right">
            <div className="circle-icon">
              <img src={ContactIcon}></img>
            </div>
            <div className="circle-icon">
              <img src={EmailIcon}></img>
            </div>
          </div>
        </div>
        <div className="text-right mt-4"></div>
      </div>
    </>
  );
}

export default UserCard;
