export const Email = ({ email, setEmail }) => (
  <>
    <div className="labels mt-80">Email Address</div>
    <input
      type="text"
      value={email}
      onChange={(e) => {
        setEmail(e.target.value);
      }}
      placeholder="Your email address"
    ></input>
  </>
);

export const Password = ({
  password,
  setPassword,
  icon,
  clickHandler,
  isReadable,
}) => (
  <>
    <div className="labels mt-4">Password</div>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></input>
    </div>
  </>
);

export const Actions = ({ onAdd }) => (
  <>
    <button className="lumi-btn mt-80" onClick={onAdd}>
      Login
    </button>
  </>
);

export const ErrorMessage = ({ error }) => (
  <>
    <h5 className="error">{error}</h5>
  </>
);
